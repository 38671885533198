import { forwardRef, useEffect, useRef, useState, Ref, useImperativeHandle } from "react"
import { AvatarConfig, Message } from "../../app/types"
import { scrollToBottomSmoothly } from "../../app/utils"
import { generateUUID } from "../../app/webrtc/utils"
import TypingIndicator from "./TypingIndicator"
import { useDispatch, useSelector } from "react-redux"
import { getHistory } from "../../app/redux/actions"
import { selectIsVoiceMode, selectIsLoadingMoreMessages, selectMessages, setLastMessageToSend, setMessages as setMessagesAction } from "../../app/redux/defaultSlice"
import VKComponent from "./VKComponent"

const scrolledToTopThreshold = 20
const messagesGap = 10

export interface ChatHistoryComponentInterface {
    addNewMessage: (msg: string, isUser: boolean) => void
}

const ChatHistoryComponent = forwardRef(({ avatarConfig, isThinking, historyContainerRef, isVKOn, setIsVKOn }: {
    avatarConfig: AvatarConfig | null,
    isThinking: boolean,
    historyContainerRef: any,
    isVKOn: boolean,
    setIsVKOn: (on: boolean) => void
}, ref: Ref<ChatHistoryComponentInterface>) => {
    useImperativeHandle(ref, () => ({
        addNewMessage: (text: string, isUser: boolean) => {
            addMessage({
                id: generateUUID(),
                text: text,
                isUser: isUser
            })

            setTimeout(() => {
                scrollToBottomSmoothly(historyContainerRef.current!, { smooth: true })
            }, 10)
        }
    }))

    const dispatch = useDispatch()

    const messages = useSelector(selectMessages) ?? []
    const addMessage = (newMsg: Message) => {
        dispatch(setMessagesAction([...(messages ?? []), newMsg]))
    }
    const areMessages = (messages.length > 0)
    useEffect(() => {
        scrollToBottomSmoothly(historyContainerRef.current!, { smooth: false })
    }, [areMessages])

    const isLoadingMoreMessages = useSelector(selectIsLoadingMoreMessages)

    const loadMoreMessages = () => {
        const avatarId = avatarConfig?.id
        if (avatarId) {
            const offsetByMessages = messages.length
            const backendOffset = offsetByMessages / 2
            dispatch(getHistory(avatarId, backendOffset))
        } else {
            console.error(`avatar isn't available`)
        }
    }

    const distToBottomRef = useRef(0)
    useEffect(() => {
        const onScroll = () => {
            distToBottomRef.current = historyContainerRef.current?.scrollHeight - historyContainerRef.current?.scrollTop

            const scrollTop = historyContainerRef.current?.scrollTop

            if (scrollTop < scrolledToTopThreshold && !isLoadingMoreMessages && messages.length > 0) {
                loadMoreMessages()
            }
        }
        historyContainerRef.current.addEventListener('scroll', onScroll)

        return () => historyContainerRef.current?.removeEventListener('scroll', onScroll)
    }, [messages])

    useEffect(() => {
        const avatarId = avatarConfig?.id
        if (avatarId) {
            dispatch(setMessagesAction([]))
            dispatch(getHistory(avatarId))
        }

    }, [avatarConfig])

    const oldestMessageIdRef = useRef('')
    useEffect(() => {
        // check if it's adding before oldest ones, then we need to preserve content position
        if (messages?.length > 0 && oldestMessageIdRef.current != '') {
            if (messages[0].id != oldestMessageIdRef.current) {
                // scroll for old history insert (like didn't move)
                const element = historyContainerRef.current
                element.scroll({
                    top: historyContainerRef.current?.scrollHeight - distToBottomRef.current,
                    left: 0,
                    behavior: 'instant',
                });
            }
        }

        if (messages?.length > 0) {
            oldestMessageIdRef.current = messages[0].id
        }
    }, [messages])

    const isVoiceMode = useSelector(selectIsVoiceMode)

    return (
        <div
            ref={historyContainerRef}
            className='px-[4px] pt-[32px] pb-[2px] h-full w-full flex flex-col overflow-auto no-scrollbar'
            style={{
                gap: messagesGap,
                maskImage: 'linear-gradient(to bottom, #000 calc(100% - 70px), transparent calc(100% - 20px))',
            }}>

            {messages.map((msg: Message) => (
                <div key={msg.id} style={msgStyle(msg)}>
                    {msg.text}
                </div>
            ))}

            <TypingIndicator style={{
                ...msgStyle({ isUser: false }),
                opacity: isThinking ? 1 : 0,
                transition: 'opacity 0.4s ease',
            }} />
            <div style={{ height: 5 }} />

            {messages.length > 0 && !isVoiceMode && <VKComponent
                shown={isVKOn && !isThinking}
                onSelect={(text: string) => {
                    dispatch(setLastMessageToSend({
                        text,
                        inputType: 'text'
                    }))
                    setIsVKOn(false)
                }} />}
        </div>
    )
});

const msgStyle = (msg: { isUser: boolean }) => ({
    background: msg.isUser ? '#0094FF' : 'transparent',
    color: msg.isUser ? 'white' : 'black',
    marginRight: msg.isUser ? 0 : 80,
    marginLeft: msg.isUser ? 80 : 0,
    alignSelf: msg.isUser ? 'flex-end' : 'flex-start',
    padding: '8px 16px',
    borderRadius: 16,
    fontSize: 16
})

export default ChatHistoryComponent