import { Action, Dispatch } from "@reduxjs/toolkit"
import { fetchHistory, fetchSuggestedTopics } from "../api"
import { setCurrentAvatarConfig, setСurrentSuggestedTopics, setMessages, setLoadingMoreMessages } from "./defaultSlice"
import { RootState } from "./store"
import { AvatarConfig, Message } from "../types"

interface MessageBatch {
    id: number
    question: string
    response: string
    created_at: string
}

export const getHistory: any = (avatarId: number, offset?: number) => {
    return async (dispatch: Dispatch<Action>, getState: () => RootState) => {
        dispatch(setLoadingMoreMessages(true))

        const state = getState().default
        const existingMessages = [...(state.messages ?? [])]

        const history: { items: [MessageBatch] } = await fetchHistory(avatarId, offset)

        let messages: Message[] = []

        for (var i = history.items.length-1; i >= 0; i--) {
            const messageBatch = history.items[i]

            const userMsg: Message = {
                id: `user-${messageBatch.id}`,
                isUser: true,
                text: messageBatch.question
            }

            const botMsg: Message = {
                id: `bot-${messageBatch.id}`,
                isUser: false,
                text: messageBatch.response
            }

            if (userMsg.text) {
                messages.push(userMsg)
            }
            messages.push(botMsg)
        }

        const combinedMessages = [...messages, ...existingMessages]

        dispatch(setMessages(combinedMessages))
        dispatch(setLoadingMoreMessages(false))
    }
}


export const setCurrentAvatarConfigAndGetSuggestedTopics: any = (avatarConfig: AvatarConfig) => {
    return async (dispatch: Dispatch<Action>, getState: () => RootState) => {
        dispatch(setCurrentAvatarConfig(avatarConfig))

        const suggestedTopics = await fetchSuggestedTopics(avatarConfig.id)
        dispatch(setСurrentSuggestedTopics(suggestedTopics))
    }
}
