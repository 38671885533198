import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from './store';
import { AvatarAnswer, AvatarConfig, Message, SuggestedTopics, UserQuestion } from '../types';

export const defaultIsLipsyncOn = true

export interface DefaultState {
    lastMessageToSend: UserQuestion | null;
    avatarAnswer: AvatarAnswer | null;
    currentAvatarConfig: AvatarConfig | null;
    messages: Message[] | null
    isLoadingMoreMessages: boolean
    avatars: AvatarConfig[] | null;
    currentSuggestedTopics: SuggestedTopics | null
    askedQuestions: string[]
    isPTTActive: boolean,
    isBotThinking: boolean,
    isBotTalking: boolean,
    isBotIntroTalking: boolean,
    isVoiceMode: boolean,
    isChatShownInVoiceMode: boolean,
    isLipsyncOn: boolean,
    isToInterrupt: boolean,
}

const initialState: DefaultState = {
    lastMessageToSend: null,
    avatarAnswer: null,
    currentAvatarConfig: null,
    messages: null,
    isLoadingMoreMessages: false,
    avatars: null,
    currentSuggestedTopics: null,
    askedQuestions: [],
    isPTTActive: false,
    isBotThinking: false,
    isBotTalking: false,
    isBotIntroTalking: false,
    isVoiceMode: false,
    isChatShownInVoiceMode: false,
    isLipsyncOn: defaultIsLipsyncOn,
    isToInterrupt: false,
};

export const defaultSlice = createSlice({
    name: 'default',
    initialState,
    reducers: {
        setLastMessageToSend: (state, action: PayloadAction<UserQuestion | null>) => {
            state.lastMessageToSend = action.payload;
        },
        setAvatarAnswer: (state, action: PayloadAction<AvatarAnswer | null>) => {
            state.avatarAnswer = action.payload;
        },
        setCurrentAvatarConfig: (state, action: PayloadAction<AvatarConfig>) => {
            state.currentAvatarConfig = action.payload;
        },
        setMessages: (state, action: PayloadAction<Message[]>) => {
            state.messages = action.payload;
        },
        setLoadingMoreMessages: (state, action: PayloadAction<boolean>) => {
            state.isLoadingMoreMessages = action.payload;
        },
        setAvatars: (state, action: PayloadAction<AvatarConfig>) => {
            state.currentAvatarConfig = action.payload;
        },
        setСurrentSuggestedTopics: (state, action: PayloadAction<SuggestedTopics>) => {
            state.currentSuggestedTopics = action.payload;
        },
        setAskedQuestions: (state, action: PayloadAction<string[]>) => {
            state.askedQuestions = action.payload;
        },
        setIsPTTActive: (state, action: PayloadAction<boolean>) => {
            state.isPTTActive = action.payload;
        },
        setIsVoiceMode: (state, action: PayloadAction<boolean>) => {
            state.isVoiceMode = action.payload;
        },
        setIsChatShownInVoiceMode: (state, action: PayloadAction<boolean>) => {
            state.isChatShownInVoiceMode = action.payload;
        },
        setIsBotThinking: (state, action: PayloadAction<boolean>) => {
            state.isBotThinking = action.payload;
        },
        setIsBotIntroTalking: (state, action: PayloadAction<boolean>) => {
            state.isBotIntroTalking = action.payload;
        },
        setIsLipsyncOn: (state, action: PayloadAction<boolean>) => {
            state.isLipsyncOn = action.payload;
        },
        setIsToInterrupt: (state, action: PayloadAction<boolean>) => {
            state.isToInterrupt = action.payload;
        },
        setIsBotTalking: (state, action: PayloadAction<boolean>) => {
            state.isBotTalking = action.payload;
        },
    },
});

export const {
    setLastMessageToSend,
    setAvatarAnswer,
    setCurrentAvatarConfig,
    setMessages,
    setLoadingMoreMessages,
    setСurrentSuggestedTopics,
    setAskedQuestions,
    setIsPTTActive,
    setIsVoiceMode,
    setIsChatShownInVoiceMode,
    setIsBotThinking,
    setIsBotIntroTalking,
    setIsLipsyncOn,
    setIsToInterrupt,
    setIsBotTalking,
} = defaultSlice.actions;

export const selectLastMessageToSend = (state: RootState) => state.default.lastMessageToSend;
export const selectAvatarAnswer = (state: RootState) => state.default.avatarAnswer;
export const selectCurrentAvatarConfig = (state: RootState) => state.default.currentAvatarConfig;
export const selectMessages = (state: RootState) => state.default.messages;
export const selectIsLoadingMoreMessages = (state: RootState) => state.default.isLoadingMoreMessages;
export const selectIsOnlyFirstIntroMessage = (state: RootState) => (state.default.messages ?? []).length == 1;
export const selectCurrentSuggestedTopics = (state: RootState) => state.default.currentSuggestedTopics;
export const selectAskedQuestions = (state: RootState) => state.default.askedQuestions;
export const selectIsPTTActive = (state: RootState) => state.default.isPTTActive;
export const selectIsVoiceMode = (state: RootState) => state.default.isVoiceMode;
export const selectIsChatShownInVoiceMode = (state: RootState) => state.default.isChatShownInVoiceMode;
export const selectIsLipsyncOn = (state: RootState) => state.default.isLipsyncOn;
export const selectIsBotThinking = (state: RootState) => state.default.isBotThinking;
export const selectIsBotIntroTalking = (state: RootState) => state.default.isBotIntroTalking;
export const selectIsToInterrupt = (state: RootState) => state.default.isToInterrupt;
export const selectIsBotTalking = (state: RootState) => state.default.isBotTalking;
export default defaultSlice.reducer;
