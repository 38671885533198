
export const StartCallButton = ({ onClick, title }: {
    onClick: () => void,
    title: string
}) => {
    return (
        <div style={{
            height: 44,
            width: '100%',
            background: '#1A2430',
            fontFamily: 'Inter',
            fontWeight: 500,
            borderRadius: 16,
            lineHeight: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            gap: 8,
            fontSize: 14,
            cursor: 'pointer',
        }}
            onClick={onClick}
        >
            <img width={16} height={16} src='/images/call-button.png' />
            <div>
                {title}
            </div>
        </div>
    )
}